<template>
  <div>
    <Navbar mode="solid" class="fixed w-full top-0" />
    <main class="page-main">
      <div class="wrapper">
        <h1 v-t="'page.terms.title'"/>
        <span class="updated-at" v-t="'page.terms.updated_at'"/>
        <h2 v-t="'page.terms.foreword.title'"/>
        <p v-t="'page.terms.foreword.text'"/>
        <h2 v-t="'page.terms.scope.title'"/>
        <h3 v-t="'page.terms.scope.general_terms.title'"/>
        <p v-t="'page.terms.scope.general_terms.text'"/>
        <h2 v-t="'page.terms.scope_of_services.title'"/>
        <p v-t="'page.terms.scope_of_services.text'"/>
        <h3 v-t="'page.terms.scope_of_services.relationship_travel_afrique_service_providers.title'" />
        <p v-t="'page.terms.scope_of_services.relationship_travel_afrique_service_providers.text'"/>
        <h3 v-t="'page.terms.scope_of_services.use_of_users_content.title'"/>
        <p v-t="'page.terms.scope_of_services.use_of_users_content.text'" />
        <h3 v-t="'page.terms.scope_of_services.liability_for_external_content.title'"/>
        <p v-t="'page.terms.scope_of_services.liability_for_external_content.text'"/>
        <h3 v-t="'page.terms.scope_of_services.availibility_accessibility.title'"/>
        <p v-t="'page.terms.scope_of_services.availibility_accessibility.text'"/>
        <h2 v-t="'page.terms.authorization.title'"/>
        <p v-t="'page.terms.authorization.text'"/>
        <h2 v-t="'page.terms.modification_of_terms.title'"/>
        <p v-t="'page.terms.modification_of_terms.text'"/>
        <h2 v-t="'page.terms.account_registration.title'"/>
        <p v-t="'page.terms.account_registration.text'"/>
        <h2 v-t="'page.terms.service_fees.title'"/>
        <p v-t="'page.terms.service_fees.text'"/>
        <h2 v-t="'page.terms.booking_services.title'"/>
        <h3 v-t="'page.terms.booking_services.your_details.title'"/>
        <p v-t="'page.terms.booking_services.your_details.text'"/>
        <h3 v-t="'page.terms.booking_services.acceptance_of_booking.title'"/>
        <p v-t="'page.terms.booking_services.acceptance_of_booking.text'"/>
        <h2 v-t="'page.terms.governing_law.title'"/>
        <p v-t="'page.terms.governing_law.text'"/>
        <h2 v-t="'page.terms.contact.title'"/>
        <p v-t="'page.terms.contact.text'"/>
      </div>
    </main>
    <Footer />
    <BottomBar class="block sm:hidden fixed bottom-0 w-full" />
  </div>
</template>
<script>
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import BottomBar from "@/components/layout/BottomBar.vue";
export default {
  name: "Terms",
  components: { Navbar, Footer, BottomBar }, 
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Travel Afrique – Terms',
  }, };
</script>
<style scoped>
.page-main {
  @apply w-full px-4 pt-36 pb-16;
}

.wrapper {
  @apply w-full max-w-screen-sm;
  @apply relative left-1/2 transform -translate-x-1/2;
}

h1,
h2,
h3 {
  @apply font-semibold text-black-base;
}

p {
  @apply text-black-base leading-normal;
  @apply mt-2 mb-4 whitespace-pre-line;
}

h1 {
  @apply text-3xl leading-tight;
}

h2 {
  @apply text-2xl leading-9;
  @apply mt-8 mb-2;
}

h3 {
  @apply mt-4 mb-2 text-lg;
}

.updated-at {
  @apply text-black-lighter text-sm;
}
</style>